export const dataSource = {
  'old-testament': [
    'old-testament/1. SÁNG THẾ.JSON',
    'old-testament/2. XUẤT HÀNH.JSON',
    'old-testament/3. LÊ-VI.JSON',
    'old-testament/4. DÂN SỐ.JSON',
    'old-testament/5. PHỤC TRUYỀN LUẬT LỆ.JSON',
    'old-testament/6. GIÔ-SUÊ.JSON',
    'old-testament/7. CÁC THỦ LÃNH.JSON',
    'old-testament/8. RU-TƠ.JSON',
    'old-testament/9. I SA-MU-ÊN.JSON',
    'old-testament/10. II SA-MU-ÊN.JSON',
    'old-testament/11. I CÁC VUA.JSON',
    'old-testament/12. II CÁC VUA.JSON',
    'old-testament/13. I SỬ KÝ.JSON',
    'old-testament/14. II SỬ KÝ.JSON',
    'old-testament/15. Ê-XƠ-RA.JSON',
    'old-testament/16. NÊ-HÊ-MI.JSON',
    'old-testament/17. Ê-XƠ-TÊ.JSON',
    'old-testament/18. GIÓP.JSON',
    'old-testament/19. THÁNH THI.JSON',
    'old-testament/20. CHÂM NGÔN.JSON',
    'old-testament/21. GIẢNG SƯ.JSON',
    'old-testament/22. NHÃ CA.JSON',
    'old-testament/23. Ê-SAI.JSON',
    'old-testament/24. GIÊ-RÊ-MI.JSON',
    'old-testament/25. AI CA.JSON',
    'old-testament/26. Ê-XÊ-CHI-ÊN.JSON',
    'old-testament/27. ÐA-NI-ÊN.JSON',
    'old-testament/28. Ô-SÊ.JSON',
    'old-testament/29. GIÔ-ÊN.JSON',
    'old-testament/30. A-MỐT.JSON',
    'old-testament/31. ÁP-ÐIA.JSON',
    'old-testament/32. GIÔ-NA.JSON',
    'old-testament/33. MI-CHÊ.JSON',
    'old-testament/34. NA-HUM.JSON',
    'old-testament/35. HA-BA-CÚC.JSON',
    'old-testament/36. SÔ-PHÔ-NI.JSON',
    'old-testament/37. HA-GAI.JSON',
    'old-testament/38. XA-CHA-RI.JSON',
    'old-testament/39. MA-LA-CHI.JSON',
  ],
  'new-testament': [
    'new-testament/1. MA-THI-Ơ.JSON',
    'new-testament/2. MÁC.JSON',
    'new-testament/3. LU-CA.JSON',
    'new-testament/4. GIĂNG.JSON',
    'new-testament/5. CÔNG VỤ CỦA CÁC SỨ ĐỒ.JSON',
    'new-testament/6. RÔ-MA.JSON',
    'new-testament/7. I CÔ-RINH-TÔ.JSON',
    'new-testament/8. II CÔ-RINH-TÔ.JSON',
    'new-testament/9. GA-LA-TI.JSON',
    'new-testament/10. Ê-PHÊ-SÔ.JSON',
    'new-testament/11. PHI-LÍP.JSON',
    'new-testament/12. CÔ-LÔ-SE.JSON',
    'new-testament/13. I THÊ-SA-LÔ-NI-CA.JSON',
    'new-testament/14. II THÊ-SA-LÔ-NI-CA.JSON',
    'new-testament/15. I TI-MÔ-THÊ.JSON',
    'new-testament/16. II TI-MÔ-THÊ.JSON',
    'new-testament/17. TÍT.JSON',
    'new-testament/18. PHI-LÊ-MÔN.JSON',
    'new-testament/19. HÊ-BƠ-RƠ.JSON',
    'new-testament/20. GIA-CƠ.JSON',
    'new-testament/21. I PHI-RƠ.JSON',
    'new-testament/22. II PHI-RƠ.JSON',
    'new-testament/23. I GIĂNG.JSON',
    'new-testament/24. II GIĂNG.JSON',
    'new-testament/25. III GIĂNG.JSON',
    'new-testament/26. GIU-ÐE.JSON',
    'new-testament/27. KHẢI HUYỀN.JSON',
  ],
}
